import React, { useState} from "react";
import { useNavigate } from "react-router-dom";
import service from "../service";

import "./SignUp.css";

export default function SignUp(props) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  async function handleSubmitSignUp(e) {
    e.preventDefault();
    if ((!firstName, !lastName, !email, !password, !confirmPassword));

    let body = {
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    };
    let signUp = await service.signup(body);

    if (signUp.data.token) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      localStorage.setItem("jwt", signUp.data.token);
      setError(signUp.data.message)

      setTimeout(() => {
        navigate("/");
        window.location.reload(true)
      }, 3000);
    } 
    
      else {
        setError(signUp.data.message);
      }
  }

  const onChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
    <div className="sign">
      <div className="signForm">

        
        <input
          className="signInp"
          type="text"
          name="firstname"
          placeholder="Prénom"
          onChange={(e) => onChange(e, setFirstName)}
        />
        <input
          className="signInp"
          type="text"
          name="lastname"
          placeholder="Nom"
          onChange={(e) => onChange(e, setLastName)}
        />
        <input
          className="signInp"
          type="email"
          name="email"
          placeholder="Email"
          required='mail'
          onChange={(e) => onChange(e, setEmail)}
        />
        <input
          type="text"
          className="signInp"
          name="password"
          placeholder="Mot de passe"
          onChange={(e) => onChange(e, setPassword)}
        />
        <input
          className="signInp"
          type="text"
          name="confirmPassword"
          placeholder="Confirmé mot de passe"
          onChange={(e) => onChange(e, setConfirmPassword)}
        />

        <h3 className="err">{error}</h3>

        <button className="signBtn" type="submit" onClick={handleSubmitSignUp}>
          Inscription
        </button>
      </div>
    </div>
  );
}
