import axios from "axios";

const baseURL = 
"https://back-end-naturopathie-et-blog.osc-fr1.scalingo.io";

//  "http://localhost:5000" 

// "https://backt2me.osc-fr1.scalingo.io"


const url = axios.create({
  baseURL,
});

const service = {
  signup(body) {
    return url.post("/users/signup", body);
  },
  logUsers(body) {
    return url.post("/users/login", body);
  },

  checkToken() {
    let jwt = localStorage.getItem("jwt");
    return url.get("/users/check-token", {
      headers: {
        Authorization: `Bearer ${jwt}`,

      },
    });
  },

  resetPassword(body){
    return url.post('/password-reset', body)
  },

  newPassword(body, userId, token){
  
    return url.post(`/password-reset/${userId}/${token}`,body)
   
   
  },

  postByUser(body) {
    let jwt = localStorage.getItem("jwt");
    return url.post("/photos/upload", body, {
      "X-Requested-With": "XMLHttpRequest",
      headers: {
        Authorization: `Bearer ${jwt}`,
      
      },
    });
  },

  delete(id) {
    let jwt = localStorage.getItem("jwt");
    return url.delete(`/users/delete/${id}`,{
      headers: {
        Authorization: `Bearer ${jwt}`,
        
      
      },
      
     
    });
  },

  displayAllArticle(body) {
    // let jwt = localStorage.getItem("jwt");
    return url.get("/users/article", body, {
      headers: {
        // Authorization: `Bearer ${jwt}`,
      },
    });
  },

};

export default service;

  // Intercepte chaque réponse du backend pour faire un joli log
  url.interceptors.response.use(
    (response) => {
      let { config, data, status, statusText } = response;
      let { method } = config;
      console.log("");
      console.log("\\\\\\\\");
      console.log(
        `RECEIVED [${method.toUpperCase()}] RESPONSE FROM BACKEND : ${
          config.baseURL
        }${config.url}`
      );
      console.log(
        `Data of type : ${typeof data} | status ${status} ${statusText}`
      );
      console.log("\\\\\\\\");
      console.log(""); 
      return response;
    },
    (err) => {
      let { config, data, status, statusText } = err.response;
      let { method } = config;
       console.log("");
      console.log("\\\\\\\\");
      console.log(
        `RECEIVED [${method.toUpperCase()}] RESPONSE FROM BACKEND : ${
          config.baseURL
        }${config.url}`
      );
      console.log(
        `Data of type : ${typeof data} | status ${status} ${statusText}`
      );
      console.log("\\\\\\\\");
      console.log(""); 
      return err.response;
    }
  );

