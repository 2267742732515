import { useState } from "react";
import { useLocation } from 'react-router-dom';

import "./ResetPassword.css";
import service from "../../service";

export default function ResetPassword() {

  const [email, setEmail] = useState();

 const location =  useLocation()
 console.log(location);

  async function handleSubmitMailForResetPassword (e){
    e.preventDefault();

    let body ={
      email: email
    }
    console.log(body);
    let mailSubmitForResetPassword = await service.resetPassword(body)
    if(mailSubmitForResetPassword){
      console.log(e.target.value,'log');
    } else{
      console.log(e.target.value);
    }
  }

  const onChange = (e, setter) => {
    setter(e.target.value);
    console.log(e.target.value);
  };


  return (
    <div className="formReset">
      <input
        className="logPass"
        type="email"
        name="email"
        placeholder="Saisir Email "
        onChange={(e)=> onChange(e, setEmail)}
      />

      <h3 className="err"></h3>

      <button onClick={handleSubmitMailForResetPassword} className="logSubmit" type="submit">
        envoyer
      </button>
    </div>
  );
}
