import React from "react";
import "./LogMain.css";

import { useState } from "react";

import Login from "./Login";
import SignUp from "./SignUp";
import LogOut from "./LogOut";


export default function Log(props) {
  const [title, setTitle] = useState("Connexion");
  const [text, setText] = useState("Je n'ai pas encore de Compte");
  const [button, setButton] = useState("S'inscrire");

  function change() {
    if (title === "Connexion") {
      setTitle("Inscription");
      setText("J'ai déjà un compte !");
      setButton("Se Connecter");
      props.setIsloggedin();
    } else {
      setTitle("Connexion");
      setText("Je n'ai pas encore de compte !");
      setButton("S'incrire");
      props.setIsloggedin();
    }
  }

  if (props.isLoggedIn === false) {
    return (
      <div className="logContent">
        <h4 className="titleLog">{title}</h4>
        {title === "Connexion" ? <Login {...props} /> : <SignUp {...props} />}
        <div className="containerBtn">
          <p className="textToLog">{text}</p>

          <button className="btnLog" onClick={change}>
            {button}
          </button>
        </div>
      </div>
    );
  } else {
    return <LogOut {...props} />;
  }
}
