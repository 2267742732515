import React from "react";
import "./Contact.css";

import facebook from '../../images/facebook.png'
import insta from '../../images/insta.png'

export default function Contact() {
  return (
    <>
      <div className="container-contact">
        <h1>Contactez-nous</h1>
        <p>Par mail : <a href="mailto:terre2mieux.etre@gmail.com">terre2mieux.etre@gmail.com</a></p>
        <p>Par téléphone : 06.11.48.75.17</p>
        <p>Visitez notre site: <a href="http://terre2mieuxetre.com" target="_blank" rel="noopener noreferrer">Terre 2 mieux être</a></p>
        <ul>
          <li><a className="lien-contact" href="https://www.facebook.com/Terre2Mieux.Etre" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Lien icône vers facebook" /></a>Facebook</li>
          <li><a className="lien-contact" href="https://www.instagram.com/terre_2mieux_etre/?utm_medium=copy_link" target="_blank" rel="noopener noreferrer"><img src={insta} alt="Lien icône vers instagram" /></a>Instagram</li>
        </ul>
      </div>
    </>
  );
}
