import './Login.css';



import React, { useState } from 'react'
import service from '../service';
import { useNavigate } from 'react-router-dom';

export default function Login(props) {

    const navigate = useNavigate()


    //  console.log(props.user);



    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
        
    async function handleSubmit(e) {
        e.preventDefault();

        if(email !== "" || password !== ""){
            let body = {
                email: email,
                password: password
            };
            let loginSub = await service.logUsers(body);
            if(loginSub.data.success){
                setEmail("");
                setPassword("");
                localStorage.setItem("jwt",loginSub.data.token);
                setError(loginSub.data.message);
            
                
                setTimeout(() => {
                    navigate('/')
                    window.location.reload(true)
                }, 3000);
            }
                
            else {
                setError(loginSub.data.message)
            }
        }
    }

    const onChange = (e, setter) => {
        setter(e.target.value);
      };
    

   


  return (
    <div className='formLogin'>
    
    

    <div className="logForm">

        

        <input className='logMail' 
        type="email"
        name='email'
        required
        placeholder='Email'
        onChange={(e)=> onChange(e, setEmail)} />   


        <input className='logPass'
        type="password"
        name='password'
        placeholder='Mot de passe'
        onChange={(e)=> onChange(e, setPassword)}
         /> 
         
         <h3 className='err'>{error}</h3>

         <button className='logSubmit'type='submit' onClick={handleSubmit}
         
        
         >Connexion</button>

        
    </div>
    
    
    
    
    
    </div>
  )
}
