import React, { useState } from "react";
import "./Home.css";
import Card from "../../Components/Card/Card";

import { useEffect } from "react";

import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import service from "../../service";

import photo from "./Cristaux.jpg";

export default function Home(props) {
  const [articles, setArticles] = useState([]);

  const isLog = props.isLoggedIn;
  const isUser = props?.user?.data?.data?.infos?.isAdmin;

  console.log(articles);


  // let urlImage = 
  // 'http://localhost:5000/photos/image/';
  // "https://back-end-naturopathie-et-blog.osc-fr1.scalingo.io/photos/blog/";
  // "https://backt2me.osc-fr1.scalingo.io/photos/image/"

  console.log(props?.user?.data?.data?.infos?.isAdmin);

  const navigate = useNavigate();

  useEffect(() => {
    service.displayAllArticle().then((res) => setArticles(res.data));
  }, []);

  async function handleClickDelete(id) {
    console.log(id, "log id");

    if (window.confirm("voulez vous supprimer l'article")) {
      let deleteSubmit = await service.delete(id);

      if (deleteSubmit.data.success) {
        console.log(deleteSubmit.data.message);

        setTimeout(() => {
          navigate("/");
          window.location.reload(true);
        }, 300);
      } else {
        console.log(deleteSubmit.data.message);
      }
    }
  }

  console.log(articles);

  return (
    <>
      <h1 className="home-title">Tous les articles</h1>

      <div className="container-cards">
        {articles
          .map((item) => {
            return (
              <Card key={uuidv4()}>
                <div className="titleAndBtnDeleteArticle">
                  <h2 className="letterCapitalize">{item.titre}</h2>
                  {isUser ? (
                    <button 
                      className="deleteBtn"
                      onClick={() => handleClickDelete(item._id)}
                    >
                      X
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                {item.pictureUrl ?  <img className="test" src={`${item.pictureUrl}`} alt="une photo" /> :  "" } 
               
               <div className="para">
                <p className="cardParaArticle">{item.contenu}</p>
               </div>
                    <div className="linkExt">
                      {item.lienFb ? <a href={item.lienFb}target="_blank" rel="noopener noreferrer">Lire sur Facebook</a>: <p></p> }
                      {item.lienInsta ? <a href={item.lienInsta}target="_blank" rel="noopener noreferrer">Lire sur Instagram</a>: <p></p> }
                    </div>
                {/* <Link
                  className="linkCardToArticle"
                  to={`articles/${item.titre.replace(/\s+/g, "-").trim()}`}
                  state={{
                    titre: item.titre,
                    contenu: item.contenu,
                    paraTwo: item.paragrapheTwo,
                    paraTree: item.paragrapheTree,
                    paraFour: item.paragrapheFour,
                    paraFive: item.paragrapheFive,
                    image: item.pictureUrl,
                    lienFbInsta: item.lienFbInsta
                    
                    
                  }}
                >
                  Lire l'article
                </Link> */}
              </Card>
            );
          })
          .reverse()}
      </div>
    </>
  );
}
