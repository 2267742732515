import React, { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import "./NewPassword.css";
import service from '../../service';

export default function NewPassword(props) {
  
  const[password, setPassword ] = useState();

  
  const location = useLocation()

  const params = useParams()

  console.log(params,'param');

  console.log(params.userId, 'USER ID');
  console.log(params.token, 'TOKEN');
  console.log(location);
  



  async function handleSubmitNewPassword (e){
    e.preventDefault();

    let body ={
      password: password,
      // userId:params.userId,
      // token: params.token 
    }
    let userId = params.userId;
    let token = params.token;
    console.log(body, userId, token );
    let SubmitForNewPassword = await service.newPassword(body, userId, token )
    if(SubmitForNewPassword){
      console.log(SubmitForNewPassword);
    
    } else{
      console.log(e.target.value);
    }
  }

  const onChange = (e, setter) => {
    setter(e.target.value);
    
  };

  

  return (
    <div className="formNewPass">
      <input
        className="logPass"
        type="text"
        name="newpass"
        placeholder="Nouveau mot de passe "
        onChange={(e)=> onChange(e, setPassword)}
       
      />

      <h3 className="err"></h3>

      <button
      onClick={handleSubmitNewPassword}  className="logSubmit" type="submit">
        envoyer
      </button>
    </div>
  )
}
