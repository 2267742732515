import Navbar from "./Components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./Containers/Home/Home";
import AddArticle from "./Containers/AddArticle/AddArticle";
import Contact from "./Containers/Contact/Contact";
import Article from "./Containers/Article/Article";
import { useEffect, useState } from "react";
import service from "./service";

import Login from "./Connexion/Login";
import LogInSignUpLogOut from "./Connexion/LogInSignUpLogOut";
import SignUp from "./Connexion/SignUp";
import NotFound from "./Components/NotFound/NotFound";
import LogOut from "./Connexion/LogOut";
import ResetPassword from "./Components/ResetPassword/ResetPassword";
import NewPassword from "./Components/NewPassword/NewPassword";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState("");

  useEffect(async () => {
    let info = await service.checkToken();
    setUser(info);
  }, [isLoggedIn]);

  useEffect(() => {
    if (user?.data?.success) {
      setIsLoggedIn(true);
      console.log(isLoggedIn);
    } else {
      setIsLoggedIn(false);
    }
  }, [user]);

  useEffect(() => {}, [isLoggedIn]);

  return (
    <>
      <Navbar user={user} isLoggedIn={isLoggedIn} />

      <Routes>
        <Route
          path="/"
          element={
            <Home
              user={user}
              setUser={setUser}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        ></Route>
        <Route path="/ecrire" element={<AddArticle />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        
        <Route
          path="/articles/:slug"
          element={
            <Article
              user={user}
              setUser={setUser}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        ></Route>
        <Route
          path="/login"
          element={
            <Login
              user={user}
              setUser={setUser}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        ></Route>
        <Route
          path="/signup"
          element={
            <SignUp
              user={user}
              setUser={setUser}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        ></Route>
        <Route path="/*" element={<NotFound />}></Route>

        <Route
          path="/logto"
          element={
            <LogInSignUpLogOut
              user={user}
              setUser={setUser}
              setIsLoggedIn={setIsLoggedIn}
              isLoggedIn={isLoggedIn}
            />
          }
        ></Route>

        <Route
          path="/deco"
          element={<LogOut setUser={setUser} setIsLoggedIn={setIsLoggedIn} />}
        ></Route>


        <Route
          path="/forgotpass"
          element={<ResetPassword user={user}
          setUser={setUser} />}
        ></Route>

        <Route
          path="/api/password-reset/:userId/:token"
          element={<NewPassword />}
        ></Route>

     
      </Routes>
    </>
  );
}

export default App;
