import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Log from "../../Connexion/LogInSignUpLogOut";
import toggleMenuImg from '../../images/menubuttonofthreelinesoutline_112563.svg'

export default function Navbar(props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);

  const isLoggedIn = props.isLoggedIn;
  const user = props?.user?.data?.data?.firstName;

  const toggleNav = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);

  console.log("nav",isLoggedIn);

  const displayButtons = () => {
    if (isLoggedIn === true && (toggleMenu || largeur > 500)) {
      return (
        <>
          {!isLoggedIn ? (
            <nav>
              <ul className="liste">
                <li className="items">
                  <Link onClick={toggleNav} to="/logto">
                    Connexion 
                  </Link>
                </li>
              </ul>

              <button onClick={toggleNav} className="btn">
                Menu
              </button>
            </nav>
          ) : (
            <nav>
              <ul className="liste">
                <li className="items">
                  <Link onClick={toggleNav} to="/">
                    Accueil
                  </Link>
                </li>

                <li className="items">
                  <Link onClick={toggleNav} to="/ecrire">
                    Écrire
                  </Link>
                </li>

                <li className="items">
                  <Link onClick={toggleNav} to="/contact">
                    Contact
                  </Link>
                </li>

                {/* mode innactif pour le moment  */}
                {/* <li className="items Start">
                  {`Connecté en tant que : ${user}`}
                </li> */}

                <li className="items ">
                  <Link onClick={toggleNav} to="/deco">
                    déconnexion
                  </Link>
                </li>
              </ul>

        <img className="btn" onClick={toggleNav} src={toggleMenuImg} alt="" />
              {/* <button onClick={toggleNav} className="btn"> */}
                
              {/* </button> */}
            </nav>
          )}
        </>
      );
    }
  };
  return (
    <nav>
      {(toggleMenu || largeur > 500) && (
        <ul className="liste liste2">
          <li className="items">
            <Link onClick={toggleNav} to="/">
              Accueil
            </Link>
          </li>
          <li className="items">
            <Link onClick={toggleNav} to="/contact">
              Contact
            </Link>
          </li>

          <li className="items">
            <Link onClick={toggleNav} to="/logto">
              connexion
            </Link>
          </li>
        </ul>
      )}
      {/* <button onClick={toggleNav} className="btn"> */}
        <img className="btn" onClick={toggleNav} src={toggleMenuImg} alt="" />
      {/* </button> */}
      {displayButtons()}
    </nav>
  );
}
