const INITIAL_STATE = {
    articles: []
}

function articleReducer(state = INITIAL_STATE, action)
{
    switch(action.type){

        case "ADDARTICLE":
            const newArr = [...state.articles];
            newArr.unshift(action.payload);
            return {
                ...state,
                articles : newArr
            }
        case "LOADARTICLES" : {
            return {
                ...state,
                articles: action.payload
            }
        }
    }
    return state;
}

export default articleReducer;