import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import Log from '../../Connexion/LogInSignUpLogOut';
import service from "../../service";
import "./Article.css";

export default function Article(props) {
  const log = props.isLoggedIn;
  console.log(log, "log");

  const moi = props.user;
  console.log(moi, "moi");
  const [articles, setArticles] = useState([]);

  // let urlImage = 
  // 'http://localhost:5000/photos/image/' 
 // "https://back-end-naturopathie-et-blog.osc-fr1.scalingo.io/photos/blog/";
// "https://backt2me.osc-fr1.scalingo.io/photos/image/"

 useEffect(() => {
    service.displayAllArticle().then((res) => setArticles(res.data));
  }, []);

  console.log(articles?.reverse());
  console.log(articles[0]?._id);

  const location = useLocation();

  console.log(location);

  return (
    <div className="article-content">
      <h2 className="blogTitle">{location.state.titre}</h2>
      <p className="paraBlog">{location.state.contenu}</p>
      <p className="paraBlog">{location.state.paraTwo}</p>
      <p className="paraBlog">{location.state.paraTree}</p>
      <p className="paraBlog">{location.state.paraFour}</p>
      <p className="paraBlog">{location.state.paraFive}</p>

      <div className="containerImgArticle">
        <img
          className="imageArticle"
          src={`${location.state.image}`}
          alt="image article"
        />
      </div>
    </div>
  );
}
