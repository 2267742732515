import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import service from "../../service";
import "./Form.css";

export default function Form() {
  const [titre, setTitre] = useState("");
  const [contenu, setContenu] = useState("");
  const [paragrapheTwo, setParagrapheTwo] = useState("");
  const [paragrapheTree, setParagrapheTree] = useState("");
  const [paragrapheFour, setParagrapheFour] = useState("");
  const [paragrapheFive, setParagrapheFive] = useState("");
  const [image, setImage] = useState("");
  const [lienFb,setLienFb] = useState("")
  const [lienInsta,setLienInsta] = useState("")
  const [error, setError] = useState("");

  const navigate = useNavigate();

  console.log(lienInsta);  

  async function handleSubmitPost(e) {
    e.preventDefault();

    let formData = new FormData(e.target);
    console.log(formData.get("titre"));
    console.log("e target", e.target);

    let body = formData;

    let postSubmit = await service.postByUser(body);
    if (postSubmit.data.success) {
      console.log(postSubmit.data);
      setError(postSubmit.data.message);

      setTimeout(() => {
        navigate("/");
      }, 2000);
    } else {
      setError(postSubmit.data.message);
    }
  }

  const onChange = (e, setter) => {
    setter(e.target.value);
  };

  return (
    <>
      <h1 className="title-form">Écrivez un article</h1>

      

      <form className="container-form" onSubmit={handleSubmitPost}>

        <label htmlFor="title">Titre</label>
        <input
          onChange={(e) => onChange(e, setTitre)}
          name="titre"
          className="inp-title"
        ></input>

        {/* <label htmlFor="title">Paragraphe 1 </label>
        <textarea
          onChange={(e) => onChange(e, setContenu)}
          name="contenu"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></textarea>

        <label htmlFor="titre">Paragraphe 2</label>
        <textarea
          onChange={(e) => onChange(e, setParagrapheTwo)}
          name="paragrapheTwo"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></textarea>

        <label htmlFor="title">Paragraphe 3 </label>
        <textarea
          onChange={(e) => onChange(e, setParagrapheTree)}
          name="paragrapheTree"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></textarea>

        <label htmlFor="title">Paragraphe 4 </label>
        <textarea
          onChange={(e) => onChange(e, setParagrapheFour)}
          name="paragrapheFour"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></textarea>

        <label htmlFor="title">Paragraphe 5 </label>
        <textarea
          onChange={(e) => onChange(e, setParagrapheFive)}
          name="paragrapheFive"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></textarea>{" "} */}

        <label htmlFor="title">Lien Facebook </label>
        <input
          onChange={(e) => onChange(e, setLienFb)}
          name="lienFb"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></input>{" "}

        <label htmlFor="title">Lien Instagram </label>
        <input
          onChange={(e) => onChange(e, setLienInsta)}
          name="lienInsta"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></input>{" "}

        <label htmlFor="title">Lien d'une image Facebook ou Instagram</label>
        <input
          onChange={(e) => onChange(e, setImage)}
          name="pictureUrl"
          className="inp-body"
          placeholder="Écrivez votre article"
        ></input>{" "}
      
        {/* <input
          onChange={(e) => {
            setImage(e.target.files[0]);
          }}
          type="file"
          name="pictureUrl"
        /> */}

        <h2 className="error">{error}</h2>
        <div className="img-center">
        
          {image ? <img className="previsualisation-image" src={image} alt="prévisualisation de l'image chargée" />:<p className="img-none">Votre image sera visible ici une fois le lien mis</p> }
        </div>

        <button>Envoyer</button>
      </form>
    </>
  );
}
